<template>
  <component
    :is="tag"
    :to="to"
    :href="href"
    :class="['zlist-group-item', { 'zlist-group-item-action': computedAction, active }]"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { ZLink } from '#components'

const props = defineProps<{
  action?: boolean
  active?: boolean
  to?: string | object
  href?: string
  button?: boolean
}>()

const computedAction = computed(() => {
  if (Boolean(props.to) || props.action || Boolean(props.href) || props.button) {
    return true
  }

  return false
})

const tag = computed(() => {
  if (props.button) {
    return 'button'
  }
  else if (Boolean(props.to) || Boolean(props.href)) {
    return ZLink
  }

  return 'div'
})
</script>
